import React from 'react';

export default function About() {
    const juan = 'juan-bw.jpg'
    const alejandro = 'ale-bw.jpg'
    const monica = 'monica-bw.jpg'
    return (
        <div className="grid lg:grid-cols-2 pt-16 pb-8 md:pt-8 px-8">
            <div>
                <h1 className="text-4xl lg:text-4xl xl:text-4xl lg:tracking-tight py-8 dark:text-black">
                    About Casaai
                </h1>
                <p className="text-xl mt-4 text-slate-500">
                    Casaai is co-founded by Monica Gonzalez, Juan Wulf, and Alejandro Lecuna,
                    three seasoned startup veterans. Drawing on their combined expertise in
                    real-state, home remodeling and business, our founders aim at shaping a
                    new era of home renovation analytics.<br /><br />With a world-className team
                    of data scientists and engineers, Casaai is introducing Artificial
                    Intelligence and machine learning to the home renovation and additions
                    industry.
                </p>
            </div>
            <div>
                <div className="flex">
                    <img src={juan} alt="Juan Wulf" className="px-8 py-8 lg:w-60 lg:h-64 w-42 h-44 bottom-0" />
                    <div>
                        <h3
                            className="text-4xl lg:text-4xl xl:text-4xl lg:tracking-tight text-slate-500 py-8 bottom-0">
                            Juan Wulf
                        </h3>
                        <p className="text-xl text-slate-900"><b>Serial entrepreneur</b></p>
                        <p className="text-xl text-slate-500">Real Estate Developer</p>
                        <p className="text-xl text-slate-500">Business strategy advisor</p>
                        <p className="text-xl text-slate-500">Global EMBA (NYU)</p>
                        <p className="text-xl text-slate-500">MSc.Mech. Eng. (London)</p>
                    </div>
                </div>
                <div className="flex">
                    <img src={monica} alt="Monica Gonzalez" className="px-8 py-8 lg:w-60 lg:h-64 w-42 h-44 bottom-0"/>
                    <div>
                        <h3
                            className="text-4xl lg:text-4xl xl:text-4xl lg:tracking-tight text-slate-500 py-8 bottom-0">
                            Monica Gonzalez
                        </h3>
                        <p className="text-xl text-slate-900"><b>Architect</b></p>
                        <p className="text-xl text-slate-500">Real Estate Developer</p>
                        <p className="text-xl text-slate-500">Licensed Realtor</p>
                        <p className="text-xl text-slate-500">Architect Cum Laude</p>
                        <p className="text-xl text-slate-500">
                            MSc. in Construction Economics & Management (UCL-London).
                        </p>
                    </div>
                </div>
                <div className="flex">
                    <img src={alejandro} alt="Alejandro Lecuna" className="px-8 py-8 lg:w-60 lg:h-64 w-42 h-44 bottom-0"/>
                    <div>
                        <h3
                            className="text-4xl lg:text-4xl xl:text-4xl lg:tracking-tight text-slate-600 py-8 bottom-0">
                            Alejandro Lecuna
                        </h3>
                        <p className="text-xl text-slate-900"><b>Design strategist</b></p>
                        <p className="text-xl text-slate-500">Startup advisor</p>
                        <p className="text-xl text-slate-500">Design Professor</p>
                        <p className="text-xl text-slate-500">MA. Berlin School of Art</p>
                        <p className="text-xl text-slate-500">School of visual Arts, NYC</p>
                    </div>
                </div>
            </div>
        </div>

    )
}