//import React, { useState, useEffect } from 'react';
//import StreetViewImage from './StreeViewImage';
import CardStreetView from './CardStreetView';

const PropertySmallCard = ({ address, line, price, lat, lon, orange=false  }) => {
    const currencyFormatter = (number) => {
        let formattedNumber = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(number);
        return formattedNumber
    }
    const cleanString = (text) => text.replaceAll('"', '').replaceAll('*', '')

    const coloredAmount = (orange) => {
        if (orange) {
            return <p className="text-orange-500"><b>{currencyFormatter(price)}</b></p>
        } else {
            return <p><b>{currencyFormatter(price)}</b></p>
        }
    }
    return (
        <div className="min-w-64 rounded-lg shadow-md">
            <CardStreetView location={`${lat},${lon}`} />
            <div className="py-2 px-2">
                {coloredAmount(orange)}
                <p>{cleanString(line)}</p>
                <br />
                <p>{address}</p>
            </div>
        </div>
    )
}

export default PropertySmallCard