import React, { useEffect, useState } from 'react';
import { Buffer } from "buffer";
import axios from 'axios';

const CardStreetView = ({ location }) => {
    const [imageUrl, setImageUrl] = useState('');
    const size = '300x100'
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY
    useEffect(() => {
        const fetchStreetViewImage = async () => {
            const url = `https://maps.googleapis.com/maps/api/streetview?size=${size}&location=${location}&key=${apiKey}`;
            try {
                const response = await axios.get(url, { responseType: 'arraybuffer' });
                const imageSrc = `data:image/jpeg;base64,${Buffer.from(response.data, 'binary').toString('base64')}`;
                setImageUrl(imageSrc);
            } catch (error) {
                console.error('Error fetching Street View image:', error);
            }
        };

        fetchStreetViewImage();
    }, [location, apiKey]);

    return (
        <div>
            {imageUrl ? (
                <img src={imageUrl} alt="Street View" className="x-auto rounded-t-lg" />
            ) : (
                <p>Loading Street View image...</p>
            )}
        </div>
    );
};

export default CardStreetView;