import React, { useState } from 'react';
import PropertyMessageModal from './PropertyMessageModal.jsx';
const CustomProgressBar = ({ label, callout, currentValue, fullValue, fullPotential = false, isVisible }) => {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);
    const handleClick = () => { }
    const percentajeOfWidth = (base, full) => {
        let percentaje = parseInt((base * 100.0) / full)
        return percentaje
    }
    const currencyFormatter = (number) => {
        let formattedNumber = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(number);
        return formattedNumber
    }
    const showCurrentValue = () => {
        console.log('callout: ', callout, ' ,currentValue: ', currentValue, ' ,fullPotential: ', fullPotential, ' ,isVisible:', isVisible)
        if (!fullPotential && isVisible) {
            return <p><b>{currencyFormatter(currentValue)}</b></p>
        } else if (fullPotential && isVisible){
            return <p><b>Unlock full potential</b></p>
        } else {
            return (
                <div>
                <div className="p-1">
                    <button className="relative w-6 h-6 bg-orange-500 rounded-full flex items-center justify-center text-white hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-300 p-2 onClick={openNotModal" onClick={openModal} >
                        <span className="text-xl"><b>?</b></span>
                    </button>
                </div>
                <PropertyMessageModal isOpen={isOpen} closeModal={closeModal} trigger={handleClick} />
                </div>
                )
        }
    }

    const showProgressBar = (visible) => {
        console.log('ProgressBar: ', callout, ', visible: ', visible)
         if (visible) {
            return (
            <div className="py-4 w-full lg:w-3/4">
                <div className="flex justify-between ">
                    <p>{label}<b>{callout}</b></p>
                    {showCurrentValue()}
                </div>

                <div className="bg-gray-300 rounded-md">
                    <div className="bg-gradient-to-r from-indigo-700 via-purple-500 to-orange-500 h-4 rounded-md" style={{ width: percentajeOfWidth(currentValue, fullValue) + '%' }} />
                </div>
            </div>)
        } else {
            return (
                <div className="py-4 w-full lg:w-3/4">
                    <div className="flex justify-between ">
                        <p>{label}<b>{callout}</b></p>
                        {showCurrentValue()}
                    </div>

                    <div className="bg-gray-300 rounded-md">
                        <div className="bg-gradient-to-r from-indigo-700 via-purple-500 to-orange-500 h-4 rounded-md" style={{ width: '0%' }} />
                    </div>
                </div>
            )
        }
    }

    return (showProgressBar(isVisible))
}

export default CustomProgressBar;