'use client'
import React, { useRef } from 'react';
//import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Header from './Header'
import Footer from './Footer'
import StreetViewImage from './StreeViewImage';
import CustomProgressBar from './CustomProgressBar';
//import PropertySmallCard from './PropertySmallCard';
import FullProtentialProgressBar from './FullProtentialProgressBar';
import CriteriaCarrousel from './CriteriaCarrousel';

const Report = ( ) => {
    const location = useLocation()
    console.log('location.state: ', location.state)
    const { data } = location.state;
    console.log('message: ', data.message)
    const cleanString = (text) => text.replaceAll('"', '').replaceAll('*', '')

    //Non state local variables
    let showCurrent = useRef(null);
    let showOne = useRef(null);
    let showOneAndOne = useRef(null);
    let showMax = useRef(null);

    const manageState = (data) => {
        if (data !== null) {
            if (data.firstCriteria !== undefined && data.firstCriteria != null && data.props !== undefined && data.props != null) {
                showCurrent = (data.firstCriteria.avg > data.props.finalValue)
                console.log('showCurrent:', showCurrent)
            } else {
                showCurrent = false
            }

            if (data.secondCriteria !== undefined && data.secondCriteria != null && data.props !== undefined && data.props != null) {
                showOne = ((data.secondCriteria.avg > data.props.finalValue) && (data.secondCriteria.avg < data.firstCriteria.avg))
                console.log('showOne:', showOne)
            } else {
                showOne = false
            }

            if (data.thirdCriteria !== undefined && data.secondCriteria != null && data.props !== undefined && data.props != null) {
                showOneAndOne = ((data.thirdCriteria.avg > data.props.finalValue) && (data.thirdCriteria.avg < data.firstCriteria.avg))
                console.log('showOneAndOne:', showOneAndOne)
            } else {
                showOneAndOne = false
            }

            if (data.thirdCriteria !== undefined && data.secondCriteria != null && data.props !== undefined && data.props != null) {
                let maxValue = data.firstCriteria?.avg
                let currentValue = data?.props?.finalValue
                let deltaValue = (maxValue - currentValue) / maxValue
                showMax = deltaValue > 0.1
            } else {
                showMax = false
            }
        }
    }

    const fullPropertyProgressPlusOneSection = (data) => {
        if (showOne) {
            return (
            <div>
                <CustomProgressBar label={'Current home value '} callout={'+ 1 Bathroom'} currentValue={data.secondCriteria.avg} fullValue={data.firstCriteria.avg} isVisible={true}/>
                    <p>Home’s in your neighborhood that have an extra bathroom</p>
                    <p className="text-2xl">+ 1 Bathroom</p>
                <CriteriaCarrousel criteria={data.secondCriteria} />
            </div>)
        } else {
            return (<div></div>)
        }
    }

    const fullPropertyProgressPlusOnePlusOneSection = (data) => {
        if (showOneAndOne) {
            return (
                <div>
                    <CustomProgressBar label={'Current home value '} callout={'+1 Bedroom + 1 Bathroom'} currentValue={data.thirdCriteria.avg} fullValue={data.firstCriteria.avg} isVisible={true} />
                    <p>Home’s in your neighborhood that have an extra bathroom & an extra bedroom</p>
                    <p className="text-2xl">+ 1 Bathroom + 1 Bedroom</p>
                    <CriteriaCarrousel criteria={data.thirdCriteria} />
                </div>)
        } else {
            return (<div></div>)
        }
    }
    
    return (
    <div >
        <Header />
            <div className="bg-gradient-to-r from-indigo-500 via-purple-500 to-red-500 h-0.5"></div>
            <div className="container mx-auto min-h-screen text-black">
                <div className="w-full py-4 px-4">
                    <div className="w-full">
                        <StreetViewImage location={`${data.props.lat},${data.props.lon}`} />
                        <h1 className="text-2xl font-semibold pt-2">{data.props.address}</h1>
                        <h3 className="text-lg pb-2">{cleanString(data.props.line)}</h3>
                        <h1 className="text-2xl">Home Potential Value</h1>
                        <h1 className="text-2xl">Preview</h1>
                        {manageState(data)}
                        <CustomProgressBar label={'Current Home Value'} callout={''} currentValue={data.props.finalValue} fullValue={data.firstCriteria.avg} isVisible={showCurrent} />
                        <CustomProgressBar label={'Current home value '} callout={'+ 1 Bathroom'} currentValue={data.secondCriteria.avg} fullValue={data.firstCriteria.avg} isVisible={showOne} />
                        <CustomProgressBar label={'Current home value '} callout={'+1 Bedroom + 1 Bathroom'} currentValue={data.thirdCriteria.avg} fullValue={data.firstCriteria.avg} isVisible={showOneAndOne} />
                        <CustomProgressBar label={''} callout={'Home Full Potential'} currentValue={data.firstCriteria.avg} fullValue={data.firstCriteria.avg} fullPotential={false} isVisible={showMax} />

                        <div>
                            <p><b>{data.message}</b></p>
                            Take a look at these comparable homes within your neighborhood. This report can help you  visualize how adding one bathroom / adding one bathroom & one bedroom /  maximizing the full potential of your lot, can actually have an effect in the value of your home.
                        </div>

                        {fullPropertyProgressPlusOneSection(data)}
                        
                        {fullPropertyProgressPlusOnePlusOneSection(data)}

                        <FullProtentialProgressBar callout={'Home Full Potential'} fullValue={data.firstCriteria.avg} />
                            <p>Home’s in your neighbourhood that have maximised their full potential, in a similar lot size.</p>
                        <CriteriaCarrousel criteria={data.firstCriteria} orange={true} />
                    </div>
                </div>
            </div>
        <Footer />
    </div>
    )
}

export default Report

/*
<CustomProgressBar label={'Current Home Value'} callout={''} currentValue={data.props.finalValue} fullValue={data.firstCriteria.avg} isVisible={true}/>
                        <CustomProgressBar label={'Current home value '} callout={'+ 1 Bathroom'} currentValue={data.secondCriteria.avg} fullValue={data.firstCriteria.avg} />
                        <CustomProgressBar label={'Current home value '} callout={'+1 Bedroom + 1 Bathroom'} currentValue={data.thirdCriteria.avg} fullValue={data.firstCriteria.avg} />
                        <FullProtentialProgressBar callout={'Home Full Potential'} fullValue={data.firstCriteria.avg} />

*/
