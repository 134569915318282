import React from 'react';

export default function Intro() {
    const image = 'hero-casa-ai-rounded.jpg'
    return (
        <div className="grid lg:grid-cols-2 place-items-center pt-16 pb-8 md:pt-8 px-8 bg-[#FFFFFF]">
            <div className="flex py-6 px-8 md:order-2 md:block">
                <img src={image} alt="Casa.ai Smart Remodeling Tool" className="rounded-lg"/>
            </div>
            <div>
                <h1
                    className="text-4xl lg:text-4xl xl:text-4xl lg:tracking-tight dark:text-black">
                    The Power of Smart Remodeling Tools in the Hands of Homeowners
                </h1>
                <p className="text-xl mt-4 text-slate-500">
                    Casaai empowers homeowners to understand and elevate their home&apos;s value
                    through smart remodeling. Our innovative technology enables users to make
                    informed design decisions, strategically targeting renovations that
                    maximize property value.
                </p>
            </div>
        </div>
    )
}