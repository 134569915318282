import React from 'react';

const FullProtentialProgressBar = ({ callout, fullValue }) => {
    //const percentajeOfWidth = (base, full) => parseInt((base * 100.0) / full)
    const currencyFormatter = (number) => {
        let formattedNumber = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(number);
        return formattedNumber
    }
    
    return (<div className="py-4 w-full lg:w-3/4">
        <div className="flex justify-between ">
            <p><b>{callout}</b></p>
            <p className="text-orange-600 text-lg"><b>{currencyFormatter(fullValue)}</b></p>
        </div>

        <div className="bg-gray-300 rounded-md">
            <div className="bg-gradient-to-r from-indigo-700 via-purple-500 to-orange-500 h-4 rounded-md" style={{ width: 100 + '%' }} />
        </div>
    </div>)
}

export default FullProtentialProgressBar;