import React from 'react';
import PropertySmallCard from './PropertySmallCard';

const CriteriaCarrousel = ({ criteria, orange=false }) => {
    console.log('criteria', criteria.props.lenght)
    return (
        <div className="overflow-x-scroll">
            <div className="flex space-x-4 py-4 px-4">
                {
                    criteria.props.map(item => {
                        return <PropertySmallCard address={item.address} line={item.line} price={item.finalValue} lat={item.lat} lon={item.lon} key={item.apn} orange={orange} />
                    })
                }
            </div>
        </div>
    )}

export default CriteriaCarrousel