import React from 'react';

const PropertyMessageModal = ({ isOpen, closeModal }) => {
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };

    const closeModalEvent = () => {
        closeModal();
    }
    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={handleOverlayClick} >
            <div className="w-full max-w-md p-6 bg-white rounded-md shadow-lg">
                <p className='text-xl'><b>Not enough data available</b></p>
                <p className='py-2'>
                    There is not enough data to calculate this pricing thier for your property
                </p>
                <div className='py-4'>
                    <button type="submit" className="w-full px-4 py-2 font-bold text-white bg-orange-500 rounded-full hover:bg-orange-700 focus:outline-none focus:ring focus:ring-orange-200"
                        onClick={closeModalEvent} >
                        <p>Close</p>
                    </button>
                </div>
            </div>
        </div>)
};

export default PropertyMessageModal;