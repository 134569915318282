import React from 'react';

const featureList = [
    {
        key:1,
        title: "Locate home",
        description: "We first need to locate your home",
        icon: "home_location.png",
    },
    {
        key:2,
        title: "Run Analysis",
        description: "Our algorithms and AI analyse your home.",
        icon: "home_search.png",
    },
    {
        key:3,
        title: "Get Value Report",
        description: "You receive a free report on your home’s potential value.",
        icon: "home_report.png",
    },
]

export default function Features() { 
    return (
        <div className="grid sm:grid-cols-1 md:grid-cols-3 mt-16 gap-16">
            {featureList.map((item) => (
                <div className="gap-8 text-center items-center justify-center" key={item.key}>
                    <img src={item.icon} alt={item.icon}  className="object-none object-center mx-auto" />
                    <h1 className="text-3xl lg:text-3xl xl:text-3xl lg:tracking-tight dark:text-black">
                        {item.title}
                    </h1>
                    <p className="text-slate-500 mt-2 leading-relaxed">{item.description}</p>
                </div>
            ))}
        </div>
    )
}