import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AddressList from './components/AddressList';
import AddressDetails from './components/AddressDetails';
import Report from './components/PropertyReport';
import Lead from './components/PropertyLead';
import './App.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <AddressList />
  },
  {
    path: "/details",
    element: <AddressDetails />
  },
  {
    path: "/lead",
    element: <Lead />
  },
  {
    path: "/report",
    element: <Report />
  }

]);

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <RouterProvider router={router} />  
      </header>
    </div>
  );
}

export default App;
