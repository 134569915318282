// src/AddressList.jsx
'use client'
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadScript } from "@react-google-maps/api";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Header from './Header';
import Footer from './Footer'
import Features from './Features'
import Intro from './Intro'
import About from './About'

const AddressList = () => {
    //const location = useLocation()
    const key = process.env.REACT_APP_GOOGLE_MAPS_KEY
    const libraries = ["places"]
    const [address, setAddress] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        // This effect will run every time `someProp` changes
        if (address) {
            // Perform your action here, such as changing the URL
            navigate('/details', { state: { address: address.value.description } });
            console.log('Selected Address: ', address.value.description)
        }
    }, [address, navigate, key]);

    return (
        <div className="bg-white">
            <Header />
            <div className="bg-gradient-to-r from-indigo-500 via-purple-500 to-red-500 h-1.5"></div>
            <div className="container mx-auto p-4">
                <div className="bg-cover bg-top h-80 w-full items-center justify-center rounded-lg" style={{ backgroundImage: "url('/div-background.jpg')" }}>
                    <h1 className="text-4xl lg:text-4xl xl:text-4xl lg:tracking-tight dark:text-black text-center py-8" style={{ offset: 16 }}>Find your home&apos;s potential value</h1>
                    <div className="lg:px-64 px-16">
                        <LoadScript id="script-loader" googleMapsApiKey={key} libraries={libraries} >  
                            <GooglePlacesAutocomplete apiKey={key} selectProps={{ address, onChange: setAddress, }} styles={{
                                textInputContainer: {
                                    backgroundColor: 'grey',
                                },
                                textInput: {
                                    height: 38,
                                    color: '#5d5d5d',
                                    fontSize: 16,
                                },
                                predefinedPlacesDescription: {
                                    color: '#1faadb',
                                },
                            }} />
                        </LoadScript>
                    </div>
                </div>
                <Features />
                <Intro />
                <About />
            </div>
            <Footer />
        </div>
    );
};

export default AddressList;

/**
 *  <LoadScript googleMapsApiKey={key} libraries={libraries}>
 * </LoadScript>
 */