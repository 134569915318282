import React, { useState } from 'react';
import PropertyNonFoundModal from './PropertyNonFoundModal';
const PropertyNotFound = ( {address} ) => {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    return (
        <div className="container mx-auto min-h-screen text-black">
            <div className="w-full py-4">
                <div className="w-full">
                    <h1 className="text-2xl font-semibold pt-2">{address}</h1>
                    <h1 className="text-2xl">Home Potential Value</h1>
                    <h1 className="text-2xl">Preview</h1>
                    <div className="py-4">
                        <p>We apologize, it seems that we are not yet in your market, or there is not enough data for the address you entered.</p>
                        <p>No worries! You can reach out directly, and we can perform a customized report for your home.</p>
                    </div>
                </div>
                
                <button className="px-4 py-2 bg-orange-500 text-white rounded-full hover:bg-orange-700 focus:outline-none" onClick={openModal}>
                    <b>Contact Us</b>
                </button>
                <PropertyNonFoundModal isOpen={isOpen} closeModal={closeModal} address={address} />
            </div>
        </div >
    )
}

export default PropertyNotFound;