import React, { useState } from 'react';
const PropertyModal = ({ isOpen, closeModal, address }) => {
    const [formData, setFormData] = useState({
        fullname: '',
        email: '',
        address: address,
    });
    const [digitFormData, setDigitFormData] = useState({
        address: address,
        code: '',
        email: ''
    })
    const [message, setMessage] = useState('');
    const [showConfirm, setShowConfirm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    //const [setIsValid] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };
    const handleDigitChange = (e) => {
        const { name, value } = e.target;
        setDigitFormData({
            ...digitFormData,
            [name]: value,
        });
    };
    const apiURL = process.env.REACT_APP_API_URL
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true)
            const response = await fetch(`${apiURL}/api/requestCode`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setIsLoading(false)
                setDigitFormData({ email: formData.email, address: address, code: '' })
                const result = await response.json();
                setShowConfirm(true)

                //setMessage('Form submitted successfully!');
                // You can handle the response data here
                console.log('Response:', result);
            } else {
                setIsLoading(false)
                setMessage('Failed to submit the form.');
                console.error('Failed to submit the form:', response.statusText);
            }
        } catch (error) {
            setIsLoading(false)
            setMessage('An error occurred while submitting the form.');
            console.error('An error occurred:', error);
        }
    };

    const handleDigitSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true)
            const response = await fetch(`${apiURL}/api/addressValidateCode`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(digitFormData),
            });
            console.log('digitFormData body:', JSON.stringify(digitFormData))
            const result = await response.json();
            console.log('Response:', result);
            if (result.validCode) {
                setIsLoading(false)
                //setIsValid(true)
                setIsDisabled(true)
                setMessage(`Thank you for validating your email address, we've registered your valuation request for ${address}, our team will reach out soon with your property valuation.`);
            } else if (!result.validCode) {
                setIsLoading(false)
                setMessage('Wrong code, please verify and try again.');
                console.error('Failed to submit the form:', response.statusText);
            }
        } catch (error) {
            setIsLoading(false)
            setMessage('An error occurred while submitting the form.');
            console.error('An error occurred:', error);
        }
    };

    if (!isOpen) return null;
    if (showConfirm) {
        return (<div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={handleOverlayClick}>
            <form
                onSubmit={handleDigitSubmit}
                className="w-full max-w-md p-6 bg-white rounded-md shadow-lg"
            >
                <div className="mb-4">
                    <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="Digits">
                        6 Digits code (*)
                    </label>
                    <input
                        type="number"
                        id="Digits"
                        name="code"
                        value={digitFormData.code}
                        onChange={handleDigitChange}
                        className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                        required
                    />
                </div>
                <div className="mb-4">
                    <p>
                        Enter your 6 digits code on your inbox in order to continue.
                    </p>
                </div>
                <button
                    type="submit"
                    className="w-full px-4 py-2 font-bold text-white bg-orange-500 rounded-full hover:bg-orange-700 focus:outline-none focus:ring focus:ring-orange-200"
                    disabled={isDisabled}
                >
                    {isLoading ? (<p><b>Validating ... </b></p>) : (<p><b>Validate</b></p>)}
                </button>

                {message && (
                    <div className="mt-4 text-center text-sm text-red-500">
                        {message}
                    </div>
                )}
            </form>
        </div>)
    } else {
        return (<div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={handleOverlayClick}>
            <form
                onSubmit={handleSubmit}
                className="w-full max-w-md p-6 bg-white rounded-md shadow-lg"
            >
                <div className="mb-4">
                    <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="fullName">
                        Full Name (*)
                    </label>
                    <input
                        type="text"
                        id="fullname"
                        name="fullname"
                        value={formData.fullname}
                        onChange={handleChange}
                        className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="email">
                        Email Address (*)
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="address">
                        Property Address
                    </label>
                    <input
                        type="text"
                        id="address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                        disabled
                    />
                </div>
                <div className="mb-4">
                    <p>
                        You will recieve a 6 digits code to validate your email address and continue.
                    </p>
                </div>
                <button
                    type="submit"
                    className="w-full px-4 py-2 font-bold text-white bg-orange-500 rounded-full hover:bg-orange-700 focus:outline-none focus:ring focus:ring-orange-200"
                >
                    {isLoading ? (<p><b>Sending ... </b></p>) : (<p><b>Request contact</b></p>)}
                </button>

                {message && (
                    <div className="mt-4 text-center text-sm text-red-500">
                        <b>{message}</b>
                    </div>
                )}
            </form>
        </div>)
    }
}

export default PropertyModal;