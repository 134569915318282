// src/AddressDetails.jsx
'use client'
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import Header from './Header';
import Footer from './Footer';
import StreetViewImage from './StreeViewImage';
import CustomProgressBar from './CustomProgressBar';
import PropertyNotFound from './ProperyNotFound';
import PropertyModal from './PropertyModal';
import PropertyNonFoundModal from './PropertyNonFoundModal';

const AddressDetails = () => {
    //State vaiables
    const location = useLocation()
    const { address } = location.state;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    //Non state local variables
    let showCurrent = useRef(null);
    let showOne = useRef(null);
    let showOneAndOne = useRef(null);
    let showMax = useRef(null);

    //Utils
    const cleanString = (text) => text.replaceAll('"', '').replaceAll('*', '')
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/report', { state: { data: data } });
    };
    //Modals management
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const openNotModal = () => setIsOpenModal(true);
    const closeNotModal = () => setIsOpenModal(false);
    const apiURL = process.env.REACT_APP_API_URL

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await fetch(`${apiURL}/api/address?address=${address}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                console.log('result: ', result)
                setData(result);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [address, apiURL]);

    const manageState = (data) => {
        if (data !== null) {
            if (data.firstCriteria !== undefined && data.firstCriteria != null && data.props !== undefined && data.props != null) {
                showCurrent = (data.firstCriteria.avg > data.props.finalValue)
                console.log('showCurrent:', showCurrent)
            } else {
                showCurrent = false
            }

            if (data.secondCriteria !== undefined && data.secondCriteria != null && data.props !== undefined && data.props != null) {
                showOne = ((data.secondCriteria.avg > data.props.finalValue) && (data.secondCriteria.avg < data.firstCriteria.avg) )
                console.log('showOne:', showOne)
            } else {
                showOne = false
            }

            if (data.thirdCriteria !== undefined && data.secondCriteria != null && data.props !== undefined && data.props != null) {
                showOneAndOne = ((data.thirdCriteria.avg > data.props.finalValue) && (data.thirdCriteria.avg < data.firstCriteria.avg))
                console.log('showOneAndOne:', showOneAndOne)
            } else {
                showOneAndOne = false
            }

            if (data.thirdCriteria !== undefined && data.secondCriteria != null && data.props !== undefined && data.props != null) {
                let maxValue = data.firstCriteria?.avg
                let currentValue = data?.props?.finalValue
                let deltaValue = (maxValue - currentValue) / maxValue
                showMax = deltaValue > 0.1
            } else {
                showMax = false
            }    
        }
    }

    const showHideMax = (isVisible) => {
        if (isVisible) {
            return (<div>
                <button className="px-4 py-2 bg-orange-500 text-white rounded-full hover:bg-orange-700 focus:outline-none" onClick={openModal}>
                    <b>Unlock Full Potential Report</b>
                </button>
                <PropertyModal isOpen={isOpen} closeModal={closeModal} address={address} apn={data.props.apn} trigger={handleClick} />
            </div>)
        } else {
            return (<div>
                <div className="py-4">
                    <p>Looks like your house is close to it's full potential, leave your contact information and our team representatives will contact you back with more details about your home valuation</p>
                </div>
                <button className="px-4 py-2 bg-orange-500 text-white rounded-full hover:bg-orange-700 focus:outline-none" onClick={openNotModal}>
                    <b>Contact Us.</b>
                </button>
                <PropertyNonFoundModal isOpen={isOpenModal} closeModal={closeNotModal} address={address} apn={data.props.apn} trigger={handleClick} />
            </div>)
        }
    }

    const renderingItem = ( data, error, loading, address ) => {
        console.log('address: ', address)
        if (loading) {
            return (<div className="container mx-auto min-h-screen text-black">
                <div className="w-full py-4 px-4">
                    <div className="w-full"><p>Loading...</p></div>
                </div>
            </div>);
        }

        if (error) {
            return (<p>Error: {error}</p>);
        }

        if (data.props === null) {
            return (<PropertyNotFound address={ address } />);
        }

        if (data.props !== null) {
            return (<div className="container mx-auto min-h-screen text-black">
                <div className="w-full py-4 px-4">
                    <div className="w-full">
                        <StreetViewImage location={`${data.props.lat},${data.props.lon}`} />
                        <h1 className="text-2xl font-semibold pt-2">{data.props.address}</h1>
                        <h3 className="text-lg pb-2">{cleanString(data.props.line)}</h3>
                        <h1 className="text-2xl">Home Potential Value</h1>
                        <h1 className="text-2xl">Preview</h1>
                        <CustomProgressBar label={'Current Home Value'} callout={''} currentValue={data.props.finalValue} fullValue={data.firstCriteria.avg} isVisible={showCurrent} />
                        <CustomProgressBar label={'Current home value '} callout={'+ 1 Bathroom'} currentValue={data.secondCriteria.avg} fullValue={data.firstCriteria.avg} isVisible={showOne} />
                        <CustomProgressBar label={'Current home value '} callout={'+1 Bedroom + 1 Bathroom'} currentValue={data.thirdCriteria.avg} fullValue={data.firstCriteria.avg} isVisible={showOneAndOne} />
                        <CustomProgressBar label={''} callout={'Home Full Potential'} currentValue={data.firstCriteria.avg} fullValue={data.firstCriteria.avg} fullPotential={true} isVisible={showMax} />
                    </div>
                    <div className="py-4">
                        <p><b>Do You Want to Learn More?</b></p>
                        <p>Take a look at our report and see market value insights and local comparable homes that will help you make a more informed decision.</p>
                    </div>
                    {showHideMax(showMax)}
                </div>
            </div >)
        }
    }

    return (
        <div >
            <Header />
            <div className="bg-gradient-to-r from-indigo-500 via-purple-500 to-red-500 h-1.5"></div>
            {manageState(data)}
            {renderingItem(data, error, loading, address)}
            <Footer />
        </div>
        
    );
};

export default AddressDetails;